<script>
  import { mapActions, mapGetters, mapMutations } from "vuex";

  export default {
    data() {
      return {
        user: "",
        userAuthMethods: [],
        toggleDisplay: false,
        recoveryTitle: "Restablecer contraseña",
      };
    },
    computed: {
      ...mapGetters("user", { getUserAuthMethods: "getUserAuthMethods", getQueryUser: "getQueryUser" }),
      authMethods() {
        return this.userAuthMethods;
      },

      renderAuthMethods() {
        return this.toggleDisplay;
      },
    },
    methods: {
      ...mapActions("user", { getAuthMethods: "getAuthMethods" }),
      ...mapMutations("user", { setQueryUser: "setQueryUser" }),
      async getMethods() {
        try {
          if (!this.user || !this.user.trim()) {
            this.user = "";
            this.toggleDisplayMethod();
            this.userAuthMethods = [];
            return;
          }

          this.setQueryUser(this.user);
          await this.getAuthMethods();

          const authMethods = await this.getUserAuthMethods;
          this.userAuthMethods = authMethods;
          this.toggleDisplayMethod();

          if (!authMethods.length) {
            this.$toast.error("El usuario no existe");
          }
        } catch (e) {
          this.$toast.error(e);
        }
      },

      toggleDisplayMethod() {
        this.toggleDisplay = !this.toggleDisplay;
      },

      dispatchActions({ method, enabled }) {
        if (enabled && method !== "default") {
          this.$router.push({ name: "method", query: { method } });
        }
      },
    },

    async mounted() {
      this.user = await this.getQueryUser;
      this.userAuthMethods = await this.getUserAuthMethods;
    },
  };
</script>

<template>
  <div class="restorePass">
    <h2 class="restorePass__recoveryTitle">{{ this.recoveryTitle }}</h2>
    <form class="restorePass__form" @submit.prevent>
      <div class="restorePass__inputWrapper">
        <iconic class="restorePass__userIcon" name="userInput" />
        <input class="restorePass__input" type="text" placeholder="Email o usuario" v-model="user" autocomplete="off" :disabled="renderAuthMethods" />
      </div>
      <button class="restorePass__submit" @click="getMethods" v-if="!renderAuthMethods">Consultar</button>
      <div class="restorePass__toggleUser">
        <a class="restorePass__link" href="#" v-if="renderAuthMethods" @click="toggleDisplayMethod">Cambiar usuario</a>
      </div>
    </form>

    <div class="restorePass__authMethods">
      <div :class="`restorePass__authMethod restorePass__authMethod--${method.enabled ? 'active' : 'inactive'}`" v-for="method in authMethods" @click="dispatchActions(method)" :key="method.method">
        <figure class="restorePass__iconWrapper">
          <iconic :name="method.method" class="restorePass__methodIcon" />
        </figure>
        <p class="restorePass__authCriteria">{{ method.criteria || method.method }}</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .restorePass {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;

    &__recoveryTitle {
      text-transform: none;
      font-family: $newFont;
      font-size: 20px;
      text-align: center;
    }

    &__form {
      padding-top: 10px;
      width: 90%;
      max-width: 425px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }

    &__inputWrapper {
      width: 100%;
      border: 1px solid #e9edef;
      border-radius: 8px;
      display: flex;
      padding: 8px 10px;
      transition: all ease-in 0.3s;

      &:focus-within,
      &:focus-within .restorePass__userIcon {
        transition: all ease-in 0.3s;
        border-color: $primary-color;
        color: $primary-color;
      }
    }

    &__input {
      width: 100%;
      border: none;
      padding: 0 15px;
      font-family: $newFont_regular;

      &:disabled {
        color: #000;
      }
    }

    &__submit {
      width: 100%;
      background-color: $lipstick;
      border: none;
      border-radius: 8px;
      height: 40px;
      color: $white;
      transition: all ease-in 0.3s;
      font-family: $newFont_semiBold;
      margin-top: 20px;

      &:hover {
        opacity: 0.5;
      }
    }

    &__toggleUser {
      width: 90%;
    }

    &__link {
      padding: 3px;
      font-family: $newFont_medium;
      font-size: 15px;
      transition: all ease-in 0.3s;

      &:hover {
        text-decoration: none;
        color: $primary-color;
        border-radius: 8px;
        background-color: #bd090921;
      }
    }

    &__methodIcon {
      font-size: 30px;
      color: #bfbfbf;
    }

    &__userIcon {
      font-size: 18px;
      color: #bfbfbf;
    }

    &__authMethods {
      width: 90%;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    &__authMethod {
      background-color: #fff;
      width: 95%;
      border: 1px solid #bfbfbf;
      max-width: 425px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      word-wrap: break-word;
      transition: all ease-in 0.2s;

      &--active {
        &:hover {
          cursor: pointer;
          background-color: #e9edef;
        }
      }

      &--inactive {
        background-color: #f0f0f0;
        color: #b9b9b9;
      }

      &:last-child {
        border: none;
        cursor: initial;
        background-color: #fdf7f8;
      }

      &:last-child .restorePass__methodIcon {
        color: $primary-color;
        font-size: 20px;
      }

      &:last-child:hover {
        cursor: initial;
        background-color: #f3e4e6;
      }
    }

    &__iconWrapper {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      padding: 10px;
    }

    &__authIcon {
      width: 25px;
      height: 25px;
    }

    &__authCriteria {
      font-size: 14px;
      width: 80%;
      font-family: $newFont_regular;
    }

    @include tabletWidth() {
      &__authMethod {
        padding: 15px 5px;
      }
    }
  }
</style>
